export default {
  methods: {
    dateFormatter() {
      this.content.surgicalProcedures.procedures.forEach((el) => {
        el.surgicalProcedureDate = el.surgicalProcedureDate
          ? new Date(el.surgicalProcedureDate)
          : null;
      });
    },
  },
};
