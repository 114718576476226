<template>
  <div id="discontinuation">
    <h5>Discontinuation</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification v-if="getDataSuccess" type="info" :message="infoText" />

    <b-overlay :show="saveDataLoading">
      <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
        <!-- Reason for discontinuation -->
        <h6>Reason for discontinuation</h6>
        <radiobutton
          v-if="handleLogicStatement('reasonForDiscontinuation', 'visibility')"
          v-model="content.discontinuation.reasonForDiscontinuation"
          class="mt-3"
          name="reasonForDiscontinuation"
          type="stacked"
          :content="content"
          :options="options.reasonsForDiscontinuationOpt"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement('reasonForDiscontinuation', 'disable')"
          @inputEvent="updateData"
        />

        <!-- Withdraw Consent Selected -->
        <datepicker
          v-if="handleLogicStatement('patientWithdrawConsentDate', 'visibility')"
          v-model="content.discontinuation.patientWithdrawConsentDate"
          label="Enter Patient Withdraw Consent Date"
          name="patientWithdrawConsentDate"
          placeholder="Enter Patient Withdraw Consent Date"
          :content="content"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement('patientWithdrawConsentDate', 'disable')"
          @changeEvent="updateData"
        />

        <!-- Death Selected -->
        <div>
          <hr />
          <datepicker
            v-if="handleLogicStatement('patientDeathDate', 'visibility')"
            v-model="content.discontinuation.patientDeathDate"
            label="Date of Death"
            name="patientDeathDate"
            placeholder="Enter Date of Death"
            :content="content"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('patientDeathDate', 'disable')"
            @changeEvent="updateData"
          />

          <checkbox
            v-if="
              handleLogicStatement('patientCauseOfDeathUnknown', 'visibility')
            "
            v-model="content.discontinuation.patientCauseOfDeathUnknown"
            name="patientCauseOfDeathUnknown"
            label="Is patient cause of death unknown ?"
            type="switch"
            :content="content"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('patientCauseOfDeathUnknown', 'disable')"
            @inputEvent="updateData"
          />

          <text-field
            v-if="handleLogicStatement('patientCauseOfDeath', 'visibility')"
            v-model="content.discontinuation.patientCauseOfDeath"
            label="Cause Of Death"
            name="patientCauseOfDeath"
            placeholder="Cause of Death"
            :content="content"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData"
          />
        </div>

        <div
          class="text-center mt-3"
        >
          <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
            {{
              handleLogicStatement('hasStopped', 'visibility')
                ? "Start Discontinuation Visit"
                : "Save & Go to summary list"
            }}
          </button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import DiscontinuationMixin from "./Discontinuation.mixin";
import { $getInfoText } from '@/helper/globalFunction';

export default {
  mixins: [
    DiscontinuationMixin
  ],
  data: () => {
    return {
      infoText: null,
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      
      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "discontinuationOptionsInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style></style>
