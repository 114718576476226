<template>
  <div id="patient-creation">
    <custom-breadcrumb class="mt-2" :items="breadcrumbs" />

    <div class="row">
      <div class="col"></div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-12"></div>
      <div class="col"></div>
    </div>

    <div class="custom-container">
      <h6
        v-if="$route.params.state === 'new-visit'" 
        class="text-muted"
      > 
        Patient ID : {{ getPatientRef() }}
      </h6>

      <h6 
        v-else
        class="text-muted"
      > Add new patient </h6>

      <h6 class="text-muted"> Visit : {{ sequence }}</h6>
      <hr />

      <b-tabs>
        <b-tab
          v-for="(item, idx) in tabs"
          :key="idx"
          :id="item.page"
          :title="item.label"
          :active="item.page === selectedTab"
          @click="selectTab(item.page)"
        />
        <br />
        <component 
          :is="selectedTab" 
          :userData="userData"
          @get-sequence="getSequence"
        />
      </b-tabs>
    </div>
  </div>
</template>

<script>
// Import Form Components
import demographicData from "./Sections/DemographicData/Index";
import patientDiagnosis from "./Sections/PatientDiagnosis/Index";
import pompeTherapy from "./Sections/PompeTherapy/Index";
import medicalHistory from "./Sections/MedicalHistory/Index";
import surgicalProcedures from "./Sections/SurgicalProcedure/Index";
import concomitantTherapy from "./Sections/ConcomitantTherapy/Index";
import discontinuation from "./Sections/Discontinuation/Index";

// Vuex
import { 
  mapState, 
  mapMutations, 
  mapActions,
  mapGetters,
} from "vuex";

export default {
  props: {
    userData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    demographicData,
    patientDiagnosis,
    pompeTherapy,
    medicalHistory,
    surgicalProcedures,
    concomitantTherapy,
    discontinuation,
  },
  data: () => ({
    sequence: 0,
    breadcrumbs: [
      {
        key: 'home',
        text: 'Home',
        action: () => window.location.href = '/web',
      }
    ]
  }),
  computed: {
    ...mapState({
      selectedTab: (state) => state.creation.selectedTab,
      patientRef: (state) => state.creation.patientRef,
    }),
    ...mapGetters({
      tabs: 'tabs/getTabs',
    }),
  },
  mounted() {
    this.selectTab(this.$route.params.tab);
  },
  methods: {
    ...mapMutations({
      setSelectedTab: "creation/setSelectedTab",
    }),
    ...mapActions({
      reqPatientRef: 'creation/reqPatientRef',
      reconstructTabs: 'tabs/reconstructTabs',
      assignTabs: 'tabs/assignTabs',
    }),
    getPatientRef() {
      if (this.patientRef) {
        return this.patientRef;
      } else {
        this.reqPatientRef(this.$route.params.id);
        return this.patientRef;
      }
    },
    constructBreadcrumb() {
      this.breadcrumbs = [
        {
          key: 'home',
          text: 'Home',
          action: () => window.location.href = '/web',
        }
      ];
      
      if (this.$route.params.state === 'edit-visit') {
        this.breadcrumbs = [
          ...this.breadcrumbs,
          { 
            key: 'patient-overview',
            text: 'Patient Overview',
            action: () => this.goToPatientOverview(this.$route.params.id)
          },
          { 
            key: 'visit-data-edit',
            text: 'Edit Visit',
            active: true
          }
        ];
      } else if (this.$route.params.state === 'new-visit') {
         this.breadcrumbs = [
          ...this.breadcrumbs,
          { 
            key: 'new-visit',
            text: 'New Visit',
            active: true
          }
        ];
      } else if (this.$route.params.state === 'overview-visit') {
        this.breadcrumbs = [
          ...this.breadcrumbs,
          { 
            key: 'patient-overview',
            text: 'Patient Overview',
            action: () => this.goToPatientOverview(this.$route.params.id)
          },
          { 
            key: 'new-visit',
            text: 'New Visit',
            active: true
          }
        ];
      } else {
        this.breadcrumbs = [
          ...this.breadcrumbs,
          { 
            key: 'add-new-patient',
            text: 'Add New Patient',
            active: true
          }
        ];
      }
    },
    selectTab(tab) {
      this.setSelectedTab(tab);
      const state = this.$route.params.state ? this.$route.params?.state : ''
      this.$router.push({
        name: "addPatient",
        params: { 
          tab,
          id: this.$route.params.id,
          state
        },
      });
      this.constructBreadcrumb();
    },
    getSequence(seq) {
      this.sequence = seq;
    },
    goToPatientOverview(id) {
      const url = this.$router.resolve({
        name: 'patientOverview',
        params: {
          tab: 'patientOverview',
          id
        }
      }).href

      window.location.href = url;
    }
  },
};
</script>
