<template>
  <div id="concomitantTherapy">
    <h5>Concomitant Therapy</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      v-if="
        getDataSuccess &&
        handleLogicStatement('concomitantTherapyInfo', 'visibility')
      "
      type="info"
      :message="infoText"
    />

    <b-overlay :show="saveDataLoading">
      <form v-if="getDataSuccess">
        <div
          class="mt-3"
          v-for="(item, idx) in content.concomitantTherapy.medications"
          :key="idx"
        >
          <h6>
            Medication {{ `#${idx + 1}` }}
            <span v-if="idx > 0">
              <a
                class="text-danger ml-2"
                href="javascript:void(0)"
                @click="deleteMedication(idx)"
              >
                <b-icon icon="trash"></b-icon>
              </a>
            </span>
          </h6>

          <!-- Medication Category -->
          <dropdown
            v-if="handleLogicStatement('medicationCategory', 'visibility', { index: idx })"
            v-model="content.concomitantTherapy.medications[idx].medicationCategory"
            label="Medication Category"
            name="medicationCategory"
            :index="idx"
            :content="content"
            parent="medications"
            :options="options.medicationListOpt"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Other Medication Category -->
          <text-field
            v-if="handleLogicStatement('nameOtherMedication', 'visibility', { index: idx })"
            v-model="content.concomitantTherapy.medications[idx].nameOtherMedication"
            label="Other Medication Category"
            name="nameOtherMedication"
            :index="idx"
            placeholder="Other Medication Category"
            :content="content"
            parent="medications"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData"
          />

          <!-- Status -->
          <dropdown
            v-if="handleLogicStatement('concomitantTherapyStatus', 'visibility', { index: idx })"
            v-model="content.concomitantTherapy.medications[idx].concomitantTherapyStatus"
            label="Status"
            name="concomitantTherapyStatus"
            :index="idx"
            :content="content"
            parent="medications"
            :options="options.statusListOpt"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- the dates -->
          <div class="row">
            <div
              class="col"
              v-if="handleLogicStatement('concomitantTherapyStartDate', 'visibility', { index: idx })"
            >
              <text-field
                v-model="content.concomitantTherapy.medications[idx].concomitantTherapyStartDate"
                label="Therapy Start Date"
                name="concomitantTherapyStartDate"
                :index="idx"
                placeholder="Enter Therapy Start Date"
                :content="content"
                parent="medications"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div
              class="col"
              v-if="handleLogicStatement('concomitantTherapyEndDate', 'visibility', { index: idx })"
            >
              <text-field
                v-model="content.concomitantTherapy.medications[idx].concomitantTherapyEndDate"
                label="Therapy End Date"
                name="concomitantTherapyEndDate"
                :index="idx"
                placeholder="Enter Therapy End Date"
                :content="content"
                :mandatory="true"
                parent="medications"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div
              class="col"
              v-if="handleLogicStatement('concomitantTherapyUnknownDate', 'visibility', { index: idx })"
            >
              <checkbox
                v-model="content.concomitantTherapy.medications[idx].concomitantTherapyDateUnknown"
                name="concomitantTherapyDateUnknown"
                :index="idx"
                label="Is Date of Concomitant Therapy Unknown ?"
                type="switch"
                :value="!content.concomitantTherapy.medications[idx].concomitantTherapyDateUnknown" 
                :text="content.concomitantTherapy.medications[idx].concomitantTherapyDateUnknown ? 'Yes' : 'No'"
                parent="medications"
                :content="content"
                :logic="logic"
                :errors="errors"
                @inputEvent="updateData"
              />
            </div>  
          </div>
        </div>

        <!-- button to add another medication -->
        <div class="buttons">
          <button
            type="button"
            class="btn btn-sm btn-link"
            @click.prevent="addMedication"
          >
            <b-icon icon="plus" aria-hidden="true"></b-icon>
            Add another medication
          </button>
        </div>

        <div class="buttons mt-3 text-center">
          <button
            class="btn btn-sm btn-pompe-primary ml-2"
            type="button"
            @click.prevent="saveData('startAssessment')"
          >
            Save & Start Assessment
          </button>
          <button
            class="btn btn-sm btn-secondary ml-2"
            type="button"
            @click.prevent="saveData('goSummary')"
          >
            Save & Go to summary page
          </button>
        </div>
      </form>
    </b-overlay>

    <AuditBox 
      ref="auditBox" 
      :user-data="userData"
      :options="audit.options"
      :show-cancel="false"
      :loading="saveDataLoading"
      @submit="submitAudit"
      @close="refreshPage"
    />
  </div>
</template>

<script>
import AuditBox from '@/components/functional/AuditBox/index.vue';
import ConcomitantTherapyMixin from './ConcomitantTherapy.mixin';
import { getPatientRef } from '@/helper/getPatientRef';

import { $getInfoText } from '@/helper/globalFunction';

export default {
  props: {
    userData: {
      type: Object,
      default: () => ({}),
    }
  },
  components: {
    AuditBox,
  },
  mixins: [
    ConcomitantTherapyMixin
  ],
  data: () => {
    return {
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
      infoText: "",
      confirmationState: '',
      audit: {
        content: {},
        options: []
      }
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    async addMedication() {
      await this.content.concomitantTherapy.medications.push({
        medicationCategory: null,
        nameOtherMedication: "",
        concomitantTherapyStatus: null,
        concomitantTherapyStartDate: "",
        concomitantTherapyEndDate: "",
        concomitantTherapyUnknownDate: false,
      });
      this.updateData();
    },
    async deleteMedication(idx) {
      await this.content.concomitantTherapy.medications.splice(idx, 1);
      this.updateData();
    },
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "concomitantTherapy",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "concomitantTherapyInfo",
        this.options.info
      );

      this.getDataSuccess = true;
    },
    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.requestUpdateForm();
    },
    // Save & continue
    saveData(outcome) {
      this.saveDataLoading = true;

      this.$requestService
        .post(
          this.$apiEndpoints.getSpecificPage +
            `/${this.$route.params.tab}?businessKey=${this.$route.params.id}`,
          this.content.concomitantTherapy,
          (response) => this.handleSaveSuccess(response, outcome),
          this.handleFailedFormRequest
        )
        .then(() => {
          this.saveDataLoading = false;
        });
    },
    handleSaveSuccess(response, outcome) {
      // Set new navs, if there's any
      if (response.data.nav.length > 0) {
        this.assignTabs(response.data.nav);
        // Reconstruct nav, based on each of the status
        this.reconstructTab();
      }

      const { stateTypeOpt } = response.data.options;
      this.audit.content = { ...response.data.content.auditVisit };
      this.audit.options = [ ...stateTypeOpt ];
      this.openModal(outcome);
    },
    startAssessmentProcess() {
      this.$requestService.post(
        this.$apiEndpoints.startProcessAssessment + `?businessKey=${this.$route.params.id}`,
        {},
        this.handleStartProcessSuccess,
        this.handleFailedFormRequest
      );
    },
    async handleStartProcessSuccess(response) {
      const { page, nav } = response.data;
      
      const patientData = await getPatientRef(this.$route.params.id);
      const { ref } = patientData.data;
      await this.setPatientRefAssessment(ref);
      await this.assignTabs(nav);

      this.$router.push({
        name: 'assessmentData',
        params: {
          tab: page,
          id: this.$route.params.id,
        }
      });
    },
    openModal(state) {
      this.$refs.auditBox.openModal();
      this.confirmationState = state;
      this.saveDataLoading = false;
    },
    submitAudit({ 
      stateType, 
      stateOtherInfo 
    }) {
      this.saveDataLoading = true;

      let payload = {
        ...this.audit.content,
        stateType,
        stateOtherInfo,
      }

      this.$requestService
        .post(
          this.$apiEndpoints.getSpecificPage + `/auditVisit?businessKey=${this.$route.params.id}`,
          payload,
          this.handleSaveAuditSuccess,
          this.handleFailedFormRequest
        )
        .then(() => {
          this.saveDataLoading = false;
        });
    },
    refreshPage() {
      this.saveDataLoading = true;
      window.location.reload();
    },
    handleSaveAuditSuccess() {
      if (this.confirmationState === "startAssessment") {
        this.startAssessmentProcess();
      } else if (this.confirmationState === "goSummary") {
        this.$router.push({
          name: "summary",
          params: {
            tab: 'summaryPatientView'
          }
        });
      }
    }
  },
};
</script>