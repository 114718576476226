import { $getInfoText } from "@/helper/globalFunction";
export default {
  methods: {
    $getInfoText,
    checkPompePatient() {
      if (
        this.content.demographicData.patientConfirmedDiagnosisOfPompe === false
      ) {
        this.notification.isActive = true;
        this.notification.message = this.$getInfoText(
          "patientNotEligibleInfo",
          this.options.info
        );
        this.notification.type = "warning";
      } else {
        this.notification = {
          type: "",
          message: "",
          isActive: false,
        };
      }
    },
    dateFormatter() {
      this.content.demographicData.dateOfBirth = this.formatDate(
        this.content.demographicData.dateOfBirth
      );
      this.content.demographicData.datePatientConsentSigned = this.formatDate(
        this.content.demographicData.datePatientConsentSigned
      );
    },
  },
};
