<template>
  <div id="pompeTherapy">
    <h5>Pompe Therapy</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      v-if="
        getDataSuccess && handleLogicStatement('pompeTherapyInfo', 'visibility', null)
      "
      type="info"
      :message="infoText"
    />

    <b-overlay :show="saveDataLoading">
      <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
        <div
          v-for="(therapy, idx) in content.pompeTherapy.therapies"
          :key="idx"
        >

          <h6>
            Therapy {{ `#${idx + 1}` }}
            <span v-if="idx > 0">
              <a
                class="text-danger ml-2"
                href="javascript:void(0)"
                @click.prevent="deleteTherapy(idx)"
              >
                <b-icon icon="trash"></b-icon>
              </a>
            </span>
          </h6>

          <!-- Pompe Status -->
          <dropdown
            v-model="content.pompeTherapy.therapies[idx].pompeStatus"
            :index="idx"
            label="Status"
            name="pompeStatus"
            parent="therapies"
            :options="options.pompeOpt"
            :content="content"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData('pompeStatus', idx)"
          />

          <!-- dateStoppedTreatment -->
          <datepicker
            v-if="handleLogicStatement('dateStoppedTreatment', 'visibility', { index: idx })"
            v-model="content.pompeTherapy.therapies[idx].dateStoppedTreatment"
            :index="idx"
            label="Date Stopped Treatment"
            name="dateStoppedTreatment"
            placeholder="Enter Date Stopped Treatment"
            parent="therapies"
            :content="content"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData('dateStoppedTreatment', idx)"
          />

          <!-- Date Switch Treatment -->
          <datepicker
            v-if="handleLogicStatement('dateSwitchTreatment', 'visibility', { index: idx })"
            v-model="content.pompeTherapy.therapies[idx].dateSwitchTreatment"
            :index="idx"
            label="Date of Switch Treatment"
            name="dateSwitchTreatment"
            placeholder="Enter Date of Switch Treatment"
            parent="therapies"
            :content="content"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData('dateSwitchTreatment', idx)"
          />

          <!-- Drug -->
          <dropdown
            v-model="content.pompeTherapy.therapies[idx].drug"
            :index="idx"
            label="Drug"
            name="drug"
            parent="therapies"
            :content="content"
            :options="options.drugOpt"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData('drug', idx)"
          />


          <div class="row">
            <div class="col">
              <!-- Date of Beginning Treatment -->
              <datepicker
                v-if="handleLogicStatement('dateBeginningTreatment', 'visibility', { index: idx })"
                v-model="content.pompeTherapy.therapies[idx].dateBeginningTreatment"
                :index="idx"
                label="Date of Beginning Treatment"
                name="dateBeginningTreatment"
                placeholder="Enter Date of Beginning Treatment"
                parent="therapies"
                :content="content"
                :logic="logic"
                :errors="errors"
                :isDisable="handleLogicStatement('dateBeginningTreatment', 'disable', { index: idx })"
                @changeEvent="updateData('dateBeginningTreatment', idx)"
              />
            </div>
            <div 
              class="col"
              v-if="handleLogicStatement('unknownDateBeginningTreatment', 'visibility', { index: idx })" 
            >
              <!-- Is Date Beginning Treatment Unknown -->
              <label> Is Date Beginning Treatment Unkown ? </label>
              <b-form-checkbox
                class="mt-1"
                name="check-button"
                id="unknownDateBeginningTreatment"
                switch
                v-model="
                  content.pompeTherapy.therapies[idx].unknownDateBeginningTreatment
                "
                @input="eventOnInput(
                  'unknownDateBeginningTreatment',
                  ['dateBeginningTreatment'],
                  idx
                )"
              >
                <label class="checkbox-label">
                  {{
                    checkboxTextLabel(
                      content.pompeTherapy.therapies[idx].unknownDateBeginningTreatment
                    )
                  }}
                </label>
              </b-form-checkbox>
            </div>
          </div>

          <!-- Doses -->
          <div class="row">
            <div class="col">
              <text-field
                v-model="content.pompeTherapy.therapies[idx].dose"
                :index="idx"
                label="Dose (mg/kg)"
                name="dose"
                type="double"
                placeholder="Dose (mg/kg)"
                parent="therapies"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData('dose', idx)"
              />
            </div>
            <div class="col">
              <text-field
                v-model="content.pompeTherapy.therapies[idx].doseFrequency"
                :index="idx"
                label="Dose Frequency"
                name="doseFrequency"
                placeholder="Dose Frequency"
                type="text"
                tooltipMessage="Please describe the interval in text"
                parent="therapies"
                :content="content"
                :logic="logic"
                :errors="errors"
                @changeEvent="updateData('doseFrequency', idx)"
              />
            </div>
          </div>

          <!-- Infusion Duration -->
        <label> Infusion Duration (On Average) </label>

        <div class="row">
          <div class="col">
            <text-field
              v-model="content.pompeTherapy.therapies[idx].infusionDurationAverageHours"
              :index="idx"
              label="Hour(s)"
              name="infusionDurationAverageHours"
              type="double"
              placeholder="Hour(s)"
              parent="therapies"
              :content="content"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData('infusionDurationAverageHours', idx)"
            />
          </div>
          <div class="col">
            <text-field
              v-model="content.pompeTherapy.therapies[idx].infusionDurationAverageMinutes"
              :index="idx"
              label="Minute(s)"
              name="infusionDurationAverageMinutes"
              type="double"
              placeholder="Minute(s)"
              parent="therapies"
              :content="content"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData('infusionDurationAverageMinutes', idx)"
            />
          </div>
        </div>

        </div>

        <!-- button to add another disease -->
        <div class="buttons">
          <button class="btn btn-sm btn-link" @click.prevent="addTherapy">
            <b-icon icon="plus" aria-hidden="true"></b-icon>
            Add another therapy
          </button>
        </div>

        <div class="buttons text-center mt-3">
          <button class="btn btn-sm btn-pompe-primary" type="submit">
            Save & Continue
          </button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import PompeTherapyMixin from "./PompeTherapy.mixin";
import { $getInfoText } from '@/helper/globalFunction';

export default {
  mixins: [
    PompeTherapyMixin
  ],
  data: () => {
    return {
      infoText: null,
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false
      }
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    async addTherapy() {
      await this.content.pompeTherapy.therapies.push({
        pompeStatus: null,
        unknownDateBeginningTreatment: false,
        infusionDurationAverageMinutes: null,
        infusionDurationAverageHours: null,
        drug: null,
        doseFrequency: null,
        dose: null,
        dateBeginningTreatment: null,
        dateStoppedTreatment: null,
      });
      this.requestUpdateForm();
    },

    async deleteTherapy(idx) {
      await this.content.pompeTherapy.therapies.splice(idx, 1);
      this.updateData();
    },
    // return checkbox text
    checkboxTextLabel(value) {
      return value ? "Yes" : "No";
    },
    eventOnInput(currentField, affectedField, idx) {
      this.updateRelatedField(currentField, affectedField, { index: idx, parent: 'therapies' });
      this.updateData(currentField);
    },
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(null, "pompeTherapy", "GET", response.data.nav, this.$route.query.ref);

      this.content = response.data.content;
      this.options = response.data.options;
      this.logic = response.data.logic;

      this.infoText = this.$getInfoText("pompeTherapyInfo", this.options.info);

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    }
  }
};
</script>
