export default {
  methods: {
    dateFormatter() {
      this.content.pompeTherapy.therapies.forEach(el => {
        el.dateBeginningTreatment = this.formatDate(el.dateBeginningTreatment);
        el.dateStoppedTreatment = this.formatDate(el.dateStoppedTreatment);
      })
    },
  },
};
