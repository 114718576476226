export default {
  methods: {
    dateFormatter() {
      this.content.discontinuation.patientWithdrawConsentDate = this.formatDate(
        this.content.discontinuation.patientWithdrawConsentDate
      );
      this.content.discontinuation.patientDeathDate = this.formatDate(
        this.content.discontinuation.patientDeathDate
      );
    },
  },
};
