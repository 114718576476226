<template>
  <div id="medicalHistory">
    <h5>Medical History</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification v-if="getDataSuccess" type="info" :message="infoText" />

    <b-overlay :show="saveDataLoading">
      <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
        <!-- General Medical Status -->
        <text-area
          v-if="handleLogicStatement('generalMedicalStatus', 'visibility')"
          v-model="content.medicalHistory.generalMedicalStatus"
          name="generalMedicalStatus"
          label="General Medical Status"
          placeholder="Enter General Medical Status"
          :content="content"
          :logic="logic"
          :errors="errors"
          @blurEvent="updateData"
        />

        <br />

        <h6>List of Diseases</h6>

        <hr />

        <!-- List of Disease -->
        <div
          class="mt-3"
          v-for="(item, idx) in content.medicalHistory.diseases"
          :key="idx"
        >
          <h6>
            Disease {{ `#${idx + 1}` }}
            <span v-if="idx > 0">
              <a
                class="text-danger ml-2"
                href="javascript:void(0)"
                @click.prevent="deleteDisease(idx)"
              >
                <b-icon icon="trash"></b-icon>
              </a>
            </span>
          </h6>

          <!-- Disease Description -->
          <text-area
            v-if="handleLogicStatement('diseaseDescription', 'visibility', { index: idx })"
            v-model="content.medicalHistory.diseases[idx].diseaseDescription"
            :index="idx"
            name="diseaseDescription"
            label="Disease Description"
            placeholder="Enter Disease Description"
            parent="diseases"
            :content="content"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData('diseaseDescription', idx)"
          />

          <!-- Medical History Status -->
          <dropdown
            v-if="handleLogicStatement('medicalHistoryStatus', 'visibility', { index: idx })"
            v-model="content.medicalHistory.diseases[idx].medicalHistoryStatus"
            :index="idx"
            label="Status"
            name="medicalHistoryStatus"
            parent="diseases" 
            :content="content"
            :options="options.statusOpt"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData('medicalHistoryStatus', idx)"
          />

          <!-- Date of Diagnosis if status is ongoing -->
          <text-field
            v-if="handleLogicStatement('dateOfDiagnosis', 'visibility', { index: idx })"
            v-model="content.medicalHistory.diseases[idx].dateOfDiagnosis"
            :index="idx"
            label="Date of Diagnosis"
            name="dateOfDiagnosis"
            placeholder="Enter Date of Diagnosis"
            parent="diseases" 
            :content="content"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData('dateOfDiagnosis', idx)"
          />

          <!-- Date of resolution if status is ongoing -->
          <text-field
            v-if="handleLogicStatement('dateOfResolution', 'visibility', { index: idx })"
            v-model="content.medicalHistory.diseases[idx].dateOfResolution"
            :index="idx"
            label="Date of Resolution"
            name="dateOfResolution"
            placeholder="Enter Date of Resolution"
            parent="diseases" 
            :content="content"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData('dateOfResolution', idx)"
          />
        </div>

        <!-- button to add another disease -->
        <div class="buttons">
          <button class="btn btn-sm btn-link" @click.prevent="addDisease">
            <b-icon icon="plus" aria-hidden="true"></b-icon>
            Add another disease
          </button>
        </div>

        <div class="buttons mt-3 text-center">
          <button class="btn btn-sm btn-pompe-primary" type="submit">
            Save & Continue
          </button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import MedicalHistoryMixin from './MedicalHistory.mixin';
import { $getInfoText } from '@/helper/globalFunction';

export default {
  mixins: [
    MedicalHistoryMixin,
  ],
  data: () => {
    return {
      infoText: null,
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      spdConfig: {
        dayLabel: 'Date',
        dayField: 'date',
        dayModel: null,
        monthLabel: 'Month',
        monthField: 'month',
        monthModel: null,
        yearLabel: 'Year',
        yearField: 'year',
        yearModel: null,
      },
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    async addDisease() {
      await this.content.medicalHistory.diseases.push({
        diseaseDescription: null,
        medicalHistoryStatus: null,
        dateOfDiagnosis: null,
        dateOfResolution: null,
      });
      this.requestUpdateForm();
    },

    async deleteDisease(idx) {
      await this.content.medicalHistory.diseases.splice(idx, 1);
      this.updateData();
    },

    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "medicalHistory",
        "GET",
        response.data.nav,
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "medicalHistoryInfo",
        this.options.info
      );

      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null, idx = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError({ fieldName, idx, fullField: `diseases[${idx}].${fieldName}` });
      }

      this.requestUpdateForm();
    },
  },
};
</script>
