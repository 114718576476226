export default {
  methods: {
    dateFormatter() {
      this.content.patientDiagnosis.dateOfPompeDiagnosis = this.formatDate(
        this.content.patientDiagnosis.dateOfPompeDiagnosis
      );
      if (this.content.patientDiagnosis.methodOfPompeDiagnosis === "oneEnzymeAssayAndGenotype") {
        this.content.patientDiagnosis.dateOfGenotypeAssay = this.formatDate(
          this.content.patientDiagnosis.dateOfGenotypeAssay
        );
      } else if (this.content.patientDiagnosis.methodOfPompeDiagnosis === "twoEnzymeAssay") {
        this.content.patientDiagnosis.sampleDate = this.formatDate(
          this.content.patientDiagnosis.sampleDate
        );
      }
    }
  }
}